/* -----------------------------------------------------------------------------------------
	Font sizes are to be in REM unites
	Target Size / Base Size = Value
	The function font-size() can be used if want as well.
----------------------------------------------------------------------------------------- */
html { 
	-webkit-text-size-adjust: none; 
	-moz-text-size-adjust: none; 
	-ms-text-size-adjust: none; 
}
body { 
	font-family: 'latoregular';
	@include font-size(18px); 
	line-height: 1.45; 
	color: var(--white);
}
h1 { 
	font-family: 'latoblack';
	@include font-size(36px);
	line-height: 1.2;
	margin-bottom: 15px; 
}
h2 { 
	font-family: 'latoblack';
	@include font-size(24px);
	line-height: 1.2;
	margin-bottom: 15px; 
}
h3 { 
	font-family: 'latoblack';
	@include font-size(18px);  
	line-height: 1.2;
	margin-bottom: 15px; 
}
h4 { 
	font-family: 'latoblack';
	@include font-size(15px); 
	line-height: 1.2;
	margin-bottom: 15px; 
}
h5 { 
	font-family: 'latoblack';
	@include font-size(12px); 
	margin-bottom: 15px; 
}
p { 
	margin-bottom: 15px;   
}
li { 
	margin-bottom: 5px; 
}
ul, ol { 
	margin-left: 30px; 
}
ul { 
	list-style-type : disc; 
	list-style-position : inside; 
	margin-bottom: 15px; 
}
ol { 
	list-style-type : decimal; 
	list-style-position : outside; 
	margin-bottom: 15px; 
}
abbr, acronym { 
	border-bottom-width: 0; 
}
strong { 
	font-weight: bold; 
}
em { 
	font-style: italic; 
}


/* ------------------------------------------------------------------------------------------------------
	Links styling
------------------------------------------------------------------------------------------------------*/
/* General link styling */
a:link,
a:active,
a:visited { 
	text-decoration: underline; 
	color: var(--white);
}
a:hover,
a:focus { 
	text-decoration: none; 
	color: var(--white);
}