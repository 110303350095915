/* ------------------------------------------------------------------------------------------------------
	colours
------------------------------------------------------------------------------------------------------*/
:root {
  --black: #000000;
  --white: #ffffff;
  --grey_dark: #292929;
  --twiiter: #1da1f2;
  --linkedin: #0077B5
}

