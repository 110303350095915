/* ------------------------------------------------------------------------------------------------------
	General Styles
------------------------------------------------------------------------------------------------------*/
html {
	box-sizing: border-box;
	background-color: var(--grey_dark);
	text-align: center;
}

  *, *:before, *:after {
box-sizing: inherit;
}

article, aside, details, figure, footer, header, hgroup, nav, section {
	display: block;
}

img { max-width: 100%; }

/* main content header and section over hidden to stop floats breaking out */
.page-wrap {
	header,
	section {
		overflow: hidden;
	}
}