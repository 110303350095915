/* ------------------------------------------------------------------------------------------------------
	Header section styling
------------------------------------------------------------------------------------------------------*/
.site-header {

	position: relative;
	margin: 0 0 0 0;
	padding: 60px 0 0 0;

	img {
		display: block;
		border-radius: 50%;
		margin: 0 auto 0 auto;
	}
}