/* ------------------------------------------------------------------------------------------------------
	Fonts
------------------------------------------------------------------------------------------------------*/

@font-face {
    font-family: 'latoblack';
    src: url('../fonts/lato-black-webfont.woff2') format('woff2'),
         url('../fonts/lato-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latoregular';
    src: url('../fonts/lato-regular-webfont.woff2') format('woff2'),
         url('../fonts/lato-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}